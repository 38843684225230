import React, { useState } from "react";
import { Seo } from "../components/Helpers/Seo";
import Layout from "../components/Layout/Layout";
import Header from "../components/Header/Header";
import { Container, Row, Col } from "react-bootstrap";
import arrow from "./../images/cta/cta-arrow.svg";
import * as styles from "../styles/pages/contact-us.module.scss";
import { graphql } from "gatsby";
import DOMPurify from "isomorphic-dompurify";
import { NetlifyForm, Honeypot, Recaptcha } from "react-netlify-forms";

export const Head = ({ data }) => <Seo
  title={data.wpPage.seo.title}
  description={data.wpPage.seo.metaDesc}
  keywords={data.wpPage.seo.metaKeywords}
/>;


const ContactUsPage = ({ data }) => {
  const section1Content = data.wpPage.acfContactPage.contentSection1;
  const formLabels = data.wpPage.acfContactPage.formLabels;
  const [emailIsValid, setEmailIsValid] = useState(true);



  const emailRegEx = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;


  const emailInputOnChangeHandler = (event) => {
    if (event.target.value.trim().length > 0 && emailRegEx.test(event.target.value)) {
      setEmailIsValid(true);
    }
    else {
      setEmailIsValid(false);
    }
  }

  return (
    <Layout footerBackgroundClass="bg-white">
      <Container fluid style={{ paddingBottom: 120 + 'px' }}>
        <Header />
        <Container style={{ paddingTop: 120 + 'px' }}>
          <Row>
            <Col xs={12} md={6} xl={5} className={styles.introText}>
              <h1 dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(section1Content.heading)
              }} />
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(section1Content.bodyText),
                }}
              />
            </Col>
            <Col lg={2} className="d-none d-xl-block"></Col>
            <Col xs={12} md={6} xl={5}>
              <NetlifyForm
                honeypotName="bot-field"
                enableRecaptcha
                name="retromer-contact"
              >
                {({ handleChange, success, error }) => (
                  <>
                    <Honeypot />
                    {success && (
                      <h4 style={{ marginBottom: 60 + "px", marginLeft: -10 + "px" }}>Form submitted. Thank you.</h4>
                    )}
                    {error && (
                      <h4 style={{ marginBottom: 60 + "px", marginLeft: -10 + "px" }}>There was an error with the form submission. Please try again.</h4>
                    )}
                    <Row className={`${styles.fieldGroup} flex-nowrap`}>
                      <Col className={styles.formField} xs={2}>
                        <label htmlFor="fullname">{formLabels.nameLabel}</label>
                      </Col>
                      <Col>
                        <input
                          id="fullname"
                          name="fullname"
                          type="text"
                          placeholder={formLabels.namePlaceholder}
                          onChange={handleChange}
                          required
                          disabled={success}
                        />
                      </Col>
                    </Row>
                    <Row className={`${styles.fieldGroup} flex-nowrap`}>
                      <Col className={styles.formField} xs={2}>
                        <label htmlFor="email">{formLabels.emailLabel}</label>
                      </Col>
                      <Col>
                        <input
                          id="email"
                          name="email"
                          type="email"
                          placeholder={formLabels.emailPlaceholder}
                          onChange={(e) => {
                            emailInputOnChangeHandler(e);
                            handleChange(e);
                          }}
                          className={!emailIsValid ? styles.invalid : ""}
                          required
                          disabled={success}
                        />
                      </Col>
                    </Row>
                    <Row className={`${styles.fieldGroup} flex-nowrap`}>
                      <Col className={styles.formField} xs={2}>
                        <label htmlFor="subject">{formLabels.subjectLabel}</label>
                      </Col>
                      <Col>
                        <input
                          id="subject"
                          name="subject"
                          type="text"
                          placeholder={formLabels.subjectPlaceholder}
                          onChange={handleChange}
                          required
                          disabled={success}
                        />
                      </Col>
                    </Row>
                    <Row className={styles.messageFieldGroup}>
                      <Col className="px-0" xs={2}>
                        <label htmlFor="message">{formLabels.messageLabel}</label>
                      </Col>
                      <Col className={styles.messageField}>
                        <textarea
                          id="message"
                          name="message"
                          placeholder={formLabels.messagePlaceholder}
                          required
                          onChange={handleChange}
                          disabled={success}
                        />
                      </Col>
                    </Row>
                    <Row className={styles.captchaField} hidden={success}>
                      <Col>
                        <Recaptcha siteKey={process.env.GATSBY_RECAPTCHA_SITEKEY} />
                      </Col>
                    </Row>
                    <div className={styles.floatRight}>
                      <button
                        className={`${styles.cardArrow} ${styles.formButton}`}
                        type="submit"
                        disabled={success}
                      >
                        <span className={styles.cardText}>{formLabels.submitButtonLabel}</span>
                        <img src={arrow} className={styles.icon} alt={formLabels.submitButtonLabel} />
                      </button>
                    </div>
                  </>
                )}
              </NetlifyForm>
            </Col>
          </Row>
        </Container>
      </Container>
    </Layout >
  );
};

export default ContactUsPage;

export const pageQuery = graphql`
    query {
        wpPage(databaseId: { eq: 24 }) {
            seo{
                metaDesc
                title
                metaKeywords
            }
            acfContactPage {
                contentSection1 {
                    heading
                    bodyText
                }
                formLabels{
                  nameLabel
                  namePlaceholder
                  emailLabel
                  emailPlaceholder
                  subjectLabel
                  subjectPlaceholder
                  messageLabel
                  messagePlaceholder
                  submitButtonLabel
                }
            }
        }
    }
`;